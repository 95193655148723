exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-js": () => import("./../../../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-chat-page-js": () => import("./../../../src/pages/chat-page.js" /* webpackChunkName: "component---src-pages-chat-page-js" */),
  "component---src-pages-guide-books-js": () => import("./../../../src/pages/guide-books.js" /* webpackChunkName: "component---src-pages-guide-books-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-intro-js": () => import("./../../../src/pages/intro.js" /* webpackChunkName: "component---src-pages-intro-js" */),
  "component---src-pages-landing-page-js": () => import("./../../../src/pages/landing-page.js" /* webpackChunkName: "component---src-pages-landing-page-js" */),
  "component---src-pages-page-numbered-page-2-js": () => import("./../../../src/pages/page-numbered/page-2.js" /* webpackChunkName: "component---src-pages-page-numbered-page-2-js" */),
  "component---src-pages-page-numbered-page-3-js": () => import("./../../../src/pages/page-numbered/page-3.js" /* webpackChunkName: "component---src-pages-page-numbered-page-3-js" */),
  "component---src-pages-page-numbered-page-4-js": () => import("./../../../src/pages/page-numbered/page-4.js" /* webpackChunkName: "component---src-pages-page-numbered-page-4-js" */),
  "component---src-pages-page-numbered-page-5-js": () => import("./../../../src/pages/page-numbered/page-5.js" /* webpackChunkName: "component---src-pages-page-numbered-page-5-js" */),
  "component---src-pages-page-numbered-page-6-js": () => import("./../../../src/pages/page-numbered/page-6.js" /* webpackChunkName: "component---src-pages-page-numbered-page-6-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacypolicy-js": () => import("./../../../src/pages/privacypolicy.js" /* webpackChunkName: "component---src-pages-privacypolicy-js" */),
  "component---src-pages-reading-art-js": () => import("./../../../src/pages/readingArt.js" /* webpackChunkName: "component---src-pages-reading-art-js" */),
  "component---src-pages-reading-attention-js": () => import("./../../../src/pages/readingAttention.js" /* webpackChunkName: "component---src-pages-reading-attention-js" */),
  "component---src-pages-reading-js": () => import("./../../../src/pages/reading.js" /* webpackChunkName: "component---src-pages-reading-js" */),
  "component---src-pages-reading-params-js": () => import("./../../../src/pages/readingParams.js" /* webpackChunkName: "component---src-pages-reading-params-js" */),
  "component---src-pages-reading-rooms-js": () => import("./../../../src/pages/reading-rooms.js" /* webpackChunkName: "component---src-pages-reading-rooms-js" */),
  "component---src-pages-rooms-js": () => import("./../../../src/pages/rooms.js" /* webpackChunkName: "component---src-pages-rooms-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-sessions-js": () => import("./../../../src/pages/sessions.js" /* webpackChunkName: "component---src-pages-sessions-js" */),
  "component---src-pages-signin-js": () => import("./../../../src/pages/signin.js" /* webpackChunkName: "component---src-pages-signin-js" */),
  "component---src-pages-termofservice-js": () => import("./../../../src/pages/termofservice.js" /* webpackChunkName: "component---src-pages-termofservice-js" */),
  "component---src-pages-trending-js": () => import("./../../../src/pages/trending.js" /* webpackChunkName: "component---src-pages-trending-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-word-games-js": () => import("./../../../src/pages/word-games.js" /* webpackChunkName: "component---src-pages-word-games-js" */),
  "component---src-pages-write-js": () => import("./../../../src/pages/write.js" /* webpackChunkName: "component---src-pages-write-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

